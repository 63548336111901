import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';



import NavBar from './Components/NavBar';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import About from './Components/About';
import Contactus from './Components/Contactus';
import Service from './Components/Service';
import Project from './Components/Project';
import Blog from './Components/Blog';
import Login from './Components/Login';
import FeedbackComplaints from './Components/FeedbackComplaints';
import GrievanceRedressal from './Components/GrievanceRedressal';
import SavingAccount from './Components/SavingAccount';
import CurrentAccount from './Components/CurrentAccount';
import TermDeposits from './Components/TermDeposits';
import LoansforEveryNeed from './Components/LoansforEveryNeed';

import BankingApi from './Components/BankingApi';
import Imps from './Components/Imps';
import Upicollectionapi from './Components/Upicollectionapi';
import Bbpsapi from './Components/Bbpsapi';
import Aepsapi from './Components/Aepsapi';
import Aadharpayapi from './Components/Aadharpayapi';
import Pennydropapi from './Components/Pennydropapi';
import Becomeamerchant from './Components/Becomeamerchant';
import Becaomeanapi from './Components/Becaomeanapi';
import Gettingstarted from './Components/Gettingstarted';
import Developerportal from './Components/Developerportal';
import Contactcustomersupport from './Components/Contactcustomersupport';
import Submitaformalcomplaints from './Components/Submitaformalcomplaints';
import Escalation from './Components/Escalation';
import Externalmediationservices from './Components/Externalmediationservices';
import Regulatoryauthoritycontact from './Components/Regulatoryauthoritycontact';
import Ombudsmanservices from './Components/Ombudsmanservices';
import Importanttipsforresolution from './Components/Importanttipsforresolution';






function App() {

return(
    <BrowserRouter>
 <Header/>
 <NavBar/>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contactus" element={<Contactus/>} />
      <Route path="/service" element={<Service/>} />
      <Route path="/savingaccount" element={<SavingAccount/>} />
      <Route path="/currentaccount" element={<CurrentAccount/>} />
      <Route path="/termdeposits" element={<TermDeposits />} />
      <Route path="/loansforeveryneed" element={<LoansforEveryNeed />} />
    
      <Route path="/bankingapi" element={<BankingApi/>} />
      <Route path="/imps" element={<Imps/>} />
      <Route path="/upicollectionapi" element={<Upicollectionapi/>} />
      <Route path="/bbpsapi" element={<Bbpsapi/>} />
      <Route path="/aepsapi" element={<Aepsapi/>} />
      <Route path="/aadharpayapi" element={<Aadharpayapi/>} />
      <Route path="/pennydropapi" element={<Pennydropapi/>} />
      <Route path="/becomeamerchant" element={<Becomeamerchant/>} />
      <Route path="/becaomeanapi" element={<Becaomeanapi/>} />
      <Route path="/gettingstarted" element={<Gettingstarted/>} />
      <Route path="/developerportal" element={<Developerportal/>} />

      <Route path="/contactcustomersupport" element={<Contactcustomersupport/>} />
      <Route path="/submitaformalcomplaints" element={<Submitaformalcomplaints/>} />
      <Route path="/escalation" element={<Escalation/>} />
      <Route path="/externalmediationservices" element={<Externalmediationservices/>} />
      <Route path="/regulatoryauthoritycontact" element={<Regulatoryauthoritycontact/>} />
      <Route path="/ombudsmanservices" element={<Ombudsmanservices/>} />
      <Route path="/importanttipsforresolution" element={<Importanttipsforresolution/>} />
      

      <Route path="/project" element={<Project/>} />
      <Route path="/feedbackcomplaints" element={<FeedbackComplaints/>} />
      <Route path="/grievanceredressal" element={<GrievanceRedressal/>} />
      <Route path="/blog" element={<Blog/>} />
      <Route path="/login" element={<Login/>} />
    </Routes>
    
    <Footer/>
  </BrowserRouter>

  );
}

export default App;
