// import React from 'react'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Home() {

    const slides = [
        {
            image: 'assets/img/slider/pc.jpeg',
            // text: 'Nagar Sahkari Bank Ltd',
           
        },
        // Add additional slides with images and text
        {
            image: 'assets/img/slider/pic.jpeg',
            // text: 'Nagar Sahkari Bank Ltd',
        }
       
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    };

    useEffect(() => {
        // Automatically slide every 5000 milliseconds (5 seconds)
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        // Clear the interval when the component is unmounted or when the currentSlide changes
        return () => clearInterval(intervalId);
    }, [currentSlide]);

    return (

        <main class="main">
            <main class="home-3 main">
                
            <div className="slider-container">
                    <div className="slider-content" style={sliderContentStyle}>
                        <h2>{slides[currentSlide].text}</h2>
                        {/* Add more content elements as needed */}
                        <p></p>

                        {/* <p style={{fontSize:'30px',fontFamily:'sans-serif'}}>Nagar Sahkari Bank Ltd</p> */}
                    </div>
                    <img src={slides[currentSlide].image} alt={`Slide ${currentSlide + 1}`} style={sliderImageStyle} />
                    {/* <button onClick={prevSlide}>Previous</button>
                    <button onClick={nextSlide}>Next</button> */}
                </div>
                {/* <div>
                    
                    <img src="assets/img/slider/Bank wallpaper.jpg" alt="Slide 1" style={{minHeight:'100pt',width:'100%'}}/>
                </div> */}
                {/* <div>
                    <img src="assets/img/slider/banner.png" alt="Slide 2" />
                </div> */}
                {/* <div>
                    <img src="assets/img/slider/banner.png" alt="Slide 3" />
                </div> */}
                {/* 
                <div class="hero-section">
                    <div class="hero-single" style={{ backgroundImage: 'url("assets/img/slider/banner.png" )',minHeight:'100px' }} >
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-7 col-lg-7">
                                    <div class="hero-content">
                                        <h6 class="hero-sub-title wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay=".25s">Great Experience In Painting</h6>
                                        <h1 class="hero-title wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay=".50s">
                                            BEST PAINTING SERVICE COMPANY
                                        </h1>
                                        <p class="wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay=".75s">
                                            There are many variations of passages available but the majority have suffered
                                            alteration in some form by injected humour or randomised words.
                                        </p>
                                        <div class="hero-btn wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                                            <a href="contact.html" class="theme-btn">Contact Us<i class="far fa-arrow-right"></i></a>
                                            <a href="about.html" class="theme-btn theme-btn2">About Us<i class="far fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </main>
            <div class="feature-area pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item" style={{ backgroundColor: 'green' }}>
                                {/* <i class="flaticon-paint-brushes"></i> */}
                                <div class="feature-content">
                                    <h4><a href="#">Term Deposit  </a></h4>
                                    <p> Enjoy a competitive 7 month term deposit rate of 4.55% p.a. Find out more..</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item" style={{ backgroundColor: 'green' }}>
                                {/* <i class="flaticon-graphic-tool"></i> */}
                                <div class="feature-content">
                                    <h4><a href="#">Savings Accounts</a></h4>
                                    <p>Earn up to 5.15% p.a. for the first four months with our Hi Saver Account.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item" style={{ backgroundColor: 'green' }}>
                                {/* <i class="flaticon-paint"></i> */}
                                <div class="feature-content">
                                    <h4><a href="#">APIs</a></h4>
                                    <p>Support your company by taking all kind of APIs from one Bank. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item" style={{ backgroundColor: 'green' }}>
                                {/* <i class="flaticon-paint"></i> */}
                                <div class="feature-content">
                                    <h4><a href="#">Get Insured</a></h4>
                                    <p>Help protect the things that matter to you. We offer a range of insurance options.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-md-6 col-lg-3">
                            <div class="feature-item" style={{ backgroundColor: 'green' }}>
                                <i class="flaticon-pantone"></i>
                                <div class="feature-content">
                                    <h4><a href="#">Great Support</a></h4>
                                    <p>There are many variations of passages available but the majority have suffered in some form by injected humour.</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


            <div class="about-area py-120">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-left" >
                                <div class="">
                                    <img src="assets/img/about/aboutBg.svg" alt />
                                </div>
                                <div class="">
                                    {/* <h1>25 <span>+</span></h1> */}
                                    {/* <span class="about-experience-text">Years Of Experience</span> */}
                                </div>
                                <div class="about-shape">
                                    {/* <img src="assets/img/about/shape.svg" alt /> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-right">
                                <div class="site-heading mb-3">
                                    <span class="site-title-tagline" style={{ color: 'green' }}>About Nagar Sahkari Bank Ltd.</span>

                                    <h5 class="site-title" style={{ color: '#ff4d5e' }}>We have been working very efficiently<span style={{ color: 'green' }}>  with loan and funding for 25+ years.</span> </h5>
                                </div>
                                <p class="about-text">NSBL is redefining banking by placing our members at the heart of everything we do. As a cooperative bank, we operate with a unique philosophy that sets us apart from traditional financial institutions. We are owned by our members, operated for their benefit, and guided by the principles of trust, transparency, and community.</p>
                                {/* <div class="about-list-wrapper">
                                    <ul class="about-list list-unstyled">
                                        <li>
                                            <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                                            <div class="text">
                                                <p>Take a look at our round up of the best shows</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                                            <div class="text">
                                                <p>It has survived not only five centuries</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                                            <div class="text">
                                                <p>Lorem Ipsum has been the ndustry standard dummy text</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                                {/* <a href="about.html" class="theme-btn" style={{ backgroundColor: 'green' }}>Discover More <i class="far fa-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>














            {/* <div class="team-area pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline" style={{ color: 'green' }}>Team</span>
                                <h2 class="site-title" style={{ color: '#ff4d5e' }}>Meet <span style={{ color: 'green' }}>Experts</span></h2>
                                <div class="heading-divider"></div>
                                <p>
                                    It is a long established fact that a reader will be distracted
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="team-item">
                                <img src="assets/img/team/img.jpg" alt="thumb" />
                                <div class="team-social">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                                <div class="team-content">
                                    <div class="team-bio">
                                        <h5><a href="#">Malissa Fierro</a></h5>
                                        <span>Painter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="team-item">
                                <img src="assets/img/team/img2.jpg" alt="thumb" />
                                <div class="team-social">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                                <div class="team-content">
                                    <div class="team-bio">
                                        <h5><a href="#">Arron Rodri</a></h5>
                                        <span>Painter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="team-item active">
                                <img src="assets/img/team/img3.jpg" alt="thumb" />
                                <div class="team-social">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                                <div class="team-content">
                                    <div class="team-bio">
                                        <h5><a href="#">Chad Smith</a></h5>
                                        <span>Painter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="team-item">
                                <img src="assets/img/team/img4.jpg" alt="thumb" />
                                <div class="team-social">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                                <div class="team-content">
                                    <div class="team-bio">
                                        <h5><a href="#">Tony Pinto</a></h5>
                                        <span>Painter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            
        </main>

    )
}
const sliderContentStyle = {
    position:'absolute',
    // top: '35%',
    bottom:'50%',
    left: '50%',
    
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',  // Adjust the text color as needed
    zIndex: 1,  // Ensure the content is above the image
    //  backgroundColor:'black',
};

const sliderImageStyle = {
    width: '100%',
    height: '500px',
    display: 'block',
};