import React from 'react'
import { Link } from 'react-router-dom';

export default function GrievanceRedressal() {
    return (



        <div>
            <div className="">
                <div className="" id="banner">
                    <div className="" style={{ position: 'relative' }}>
                        <img
                            src="assets\img\blog/grievance_redressal_banner.jpg"
                            className="img-responsive"
                            alt="banner" style={{ minHeight: '200px', width: '100%' }}
                        />
                        <div style={{ position: 'absolute', top: '60%', left: '10%', transform: 'translate(-1%, -30%)' }}>
                            <h3 style={{ color: '#00406a' }}>
                                <b>Grievance Redressal</b>
                            </h3>
                            <p>We are here to resolve your queries and concerns</p>
                        </div>
                    </div>


                </div>
            </div><br></br>




            <section id="about" className="aboutSection">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="detailsBox">
                                <h2 style={{ fontSize: '28px', color: '#00406a' }}><b>Grievance Redressal Mechanism in SVC Co-operative Bank Ltd.</b></h2>
                                <p>Customers are advised that SVC Co-operative Bank Ltd. has put in place Customer Grievance Redressal Mechanism and Processes to ensure prompt in-house resolution of all customer complaints, including complaints for outsourced activities (click here for grievance redressal mechanism for outsourced activities) and in respect of services of vendors appointed by the Bank, which is as under:</p><br></br>

                                <h2 style={{ fontSize: '17px', color: '#0064a6' }}>I. Complaint Book Is Available In Each Branch For The Convenience Of The Customers To Lodge Complaint.</h2>
                                <p />In case of any complaint (including complaints for outsourced activities and in respect of services of vendors appointed by the Bank),, the matter may be first brought to the notice of concerned Branch Manager for immediate redressal. If the complaint is not redressed to the satisfaction of the customer, the matter may be taken up with Second Level contact given below.
                                <br />
                                <br />
                                <h5 >Time frame for resolving the complaints received at different levels (including complaints for outsourced activities and in respect of services of vendors appointed by the Bank): </h5>

                                <br />

                                If the complaint (including complaints for outsourced activities and in respect of services of vendors appointed by the Bank) is made with the Branch Head, it will be resolved within 4 working days from the date of the receipt of the complaint by the Branch Head.

                                <ul>
                                    <li>1. If there is no response to your complaint after expiry of 4 working days from the date of the receipt of the complaint by the Branch Head, then it may be referred to respective Divisional Manager at C.O./Zonal Office (Second level).</li>

                                    <li>2. If there is no response to your complaint after expiry of 4 working days from the date of the receipt of the complaint at the DM level, then it may be referred to AGM/DGM level (Third level).</li>
                                </ul>

                                <p />

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="heading-group">
                    <div className="row">
                        <div className="col-sm-12 col-md-10 col-lg-7">
                            <h5 className="title medium"></h5>
                            <h6 className="about-sub-title"></h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <h2 className="inner-title" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>II. Bank has 8 Zonal Offices headed by Divisional Manager / Asst. General Manager / Deputy General Manager. Branches covered under these Zones along with Telephone Nos.. of these Zonal Offices are as under:</u>
                        </h2>
                        <br />

                        <div style={{ overflowX: 'auto' }}>
                            <table className="table table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr style={{ backgroundColor: 'rgb(29, 74, 129)' }}>
                                        <th>Zone Name</th>
                                        <th>Branches Covered</th>
                                        <th>Second Level Contact</th>
                                        <th>Third Level Contact</th>
                                        <th>Contact No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Repeat this block for each row of data */}
                                    <tr>
                                        <td>Uttar Pradesh</td>
                                        <td>Etawah</td>
                                        <td>
                                            <p>
                                                <span style={{ fontSize: '11pt' }}>
                                                    <span style={{ lineHeight: '115%' }}>
                                                        <span style={{ fontFamily: 'Calibri, sans-serif' }}></span>
                                                    </span>
                                                </span>
                                            </p>
                                            {/* More content here */}
                                        </td>
                                        <td>
                                            {/* Third Level Contact */}
                                        </td>
                                        <td>+91-9999999999</td>
                                    </tr>
                                    {/* Repeat this block for other rows */}
                                </tbody>
                            </table>
                        </div>

                        <h2 className="inner-ttile">&nbsp;</h2>

                        <h2 className="inner-ttile" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>III. For queries related to Loans & Advances – SME & Large Borrowers, the customer may contact:</u>
                        </h2>

                        <p>
                            {/* <strong>Mr. Rahul Bhatnagar</strong>
                            <br />
                            Head - Corporate Banking&nbsp;
                            <br />
                            SVC Co-operative Bank Ltd.&nbsp;
                            <br />
                            SVC Tower, J. Nehru Road
                            <br /> */}
                            {/* Vakola, Santacruz (East)
                            <br /> */}
                            Raja Ganj-Tehsil Chauraha,
                            <br />
                            Contact:9999999999
                            <br />
                            <span style={{ fontSize: '12pt', fontFamily: 'Times New Roman, serif' }}>
                                <span style={{ fontFamily: 'GraphikRegular, sans-serif' }}>
                                    Email: <a href="bhatnagarr@svcbank.com " style={{ color: 'blue', textDecoration: 'underline' }}>
                                        info@gmail.com
                                        <strong>&nbsp;</strong>
                                    </a>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <h2 className="inner-ttile" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>IV. For queries related to Retail Banking, the customer may contact:</u>
                        </h2>

                        <p>
                            <span style={{ fontSize: '12pt', fontFamily: 'Times New Roman, serif' }}>
                                <span style={{ fontFamily: 'GraphikRegular, sans-serif' }}>
                                    {/* <strong>Mr. Navin Rao</strong>
                                    <br />
                                    Head–Retail Banking
                                    <br />
                                    SVC Co-operative Bank Ltd.
                                    <br />
                                    6th Floor, Dosti Pinnacle, Plot No. E-7,
                                    <br />
                                    Road No.: 22,
                                    <br /> */}
                                   Raja Ganj-Tehsil Chauraha,
                                    <br />
                                    Contact:9999999999
                                    <br />
                                    Email:{' '}
                                    <span className="MsoHyperlink" style={{ color: 'blue' }}>
                                        <span style={{ textDecoration: 'underline' }}>
                                            <a href="mailto:raonv@svcbank.com" style={{ color: 'blue', textDecoration: 'underline' }}>
                                                info@gmail.com
                                            </a>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p></p>
                        <p></p>
                        <p></p>

                        <h2 className="inner-ttile" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>V. If the customer is &nbsp;dissatisfied with the resolution provided by the above Executives, they may approach
                                the Nodal Officer of the Bank, appointed by SVC Bank under the Banking Ombudsman Scheme, 2006, at:</u>
                        </h2>

                        <p>
                            {/* <strong>Mr. Bipin M. Joshi</strong>
                            <br />
                            DGM-Operations (Nodal Officer)
                            <br />
                            SVC Co-operative Bank Ltd.
                            <br />
                            6th Floor, Dosti Pinnacle, Plot No. E-7,
                            <br />
                            Road No.: 22,
                            <br /> */}
                          Raja Ganj-Tehsil Chauraha,
                            <br />
                            Contact:9999999999
                            <br />
                            <span style={{ fontSize: '11.0pt', lineHeight: '107%' }}>
                                <span>Email :&nbsp;
                                    <a href="mailto:custgrievance@svcbank.com" style={{ color: 'blue', textDecoration: 'underline' }}>
                                        info@gmail.com
                                    </a>
                                </span>
                            </span>
                        </p>

                        <h2 className="inner-ttile">&nbsp;</h2>

                        <h2 className="inner-ttile" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>VI. If the customer is dissatisfied with the Nodal Officer’s resolution, they may approach the Managing Director of
                                the Bank at the following address:</u>
                        </h2>

                        <p>
                            {/* <strong>Mr. Ravinder Singh</strong>
                            <br />
                            Managing Director
                            <br />
                            SVC Co-operative Bank Ltd.
                            <br />
                            SVC Tower, J. Nehru Road
                            <br />
                            Vakola, Santacruz (East)
                            <br /> */}
                            Raja Ganj-Tehsil Chauraha,&nbsp;
                            <br />
                            Contact:9999999999&nbsp;
                            <br />
                            <span style={{ fontSize: '12pt', fontFamily: 'Times New Roman, serif' }}>
                                <span style={{ fontFamily: 'GraphikRegular sans-serif' }}>
                                    Email:{' '}
                                    <span className="MsoHyperlink" style={{ color: 'blue' }}>
                                        <span style={{ textDecoration: 'underline' }}>
                                            <span style={{ textDecoration: 'none' }}>
                                                <span>
                                                    <a href="mailto:singhr@svcbank.com" style={{ color: 'blue', textDecoration: 'underline' }}>
                                                        info@gmail.com
                                                    </a>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p><br></br>

                        <h5 className="inner-ttile" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>VII. If the customer is dissatisfied with the Managing Director’s reply, they may approach the concerned Banking
                                Ombudsman at the following address:</u>
                        </h5><br></br>

                        {/* More content here */}

                        <div style={{ overflowX: 'auto' }}>
                            <table border="1" cellpadding="1" cellspacing="0" className="table table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr style={{ backgroundColor: 'rgb(29, 74, 129)' }}>
                                        <th>Branch Centre</th>
                                        <th>Name & Address of the Banking Ombudsman</th>
                                        <th>Area of Operation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Etawah</td>
                                        <td className="name_&amp;_address_of_the_banking_ombudsman">
                                            {/* <strong>Ms Saraswathi Shyamprasad</strong>
                                            <br />
                                            C/o Reserve Bank of India
                                            <br />
                                            10/3/8, Nrupathunga Road
                                            <br />
                                            Bengaluru - 560 001
                                            <br />
                                            STD Code: 080
                                            <br />
                                            Tel. No. 22277660/22180221
                                            <br />
                                            Fax No. 22276114
                                            <br />
                                            Email: <a href="mailto:cms.bobengaluru@rbi.org.in">cms.bobengaluru@rbi.org.in</a> */}
                                        </td>
                                        <td className="area_of_operation">Uttar Pradesh</td>
                                    </tr>
                                    {/* Add more table rows here for other locations */}


                                </tbody>
                            </table>
                        </div>
                        <h2 className="inner-ttile" style={{ fontSize: '20px', color: '#0064a6' }}>
                            <u>VIII: Aadhaar Grievance:</u>
                        </h2>
                        <p>
                            For any queries related to Aadhaar, please visit URL{' '}
                            <a href="https://uidai.gov.in/contact-support/grievance-redressal.html">
                                https://uidai.gov.in/contact-support/grievance-redressal.html
                            </a>
                        </p>

                    </div>
                </div>
            </div>

        </div>

    )
}
