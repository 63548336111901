import React from 'react'
import { Link } from 'react-router-dom';

export default function Service() {
  return (
    
    <main class="main">

    <div 
    class="site-breadcrumb" style= {{ background: `url("assets/img/slider/banner.png")`}}>
        <div class="container">
            <h2 class="breadcrumb-title">Service Single</h2>
            <ul class="breadcrumb-menu">
                <li><Link to="/"><i class="far fa-home"></i> Home</Link></li>
                <li class="active">Service Single</li>
            </ul>
        </div>
    </div>


    <div class="service-area service-area2 pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 mx-auto">
                            <div class="site-heading text-center">
                                {/* <span class="site-title-tagline" style={{ color: 'green' }}>Services</span> */}
                                <h3 class="site-title" style={{ color: '#ff4d5e' }}>Our  <span style={{ color: 'green' }}>Services</span></h3>
                                <p>
                                We at NSB Etawah provides all accounting services to make you payment hassel free.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <div class="service-item">
                                <div class="service-img">
                                    <img src="assets/img/service/icon-16.svg" alt />
                                </div>
                                {/* <div class="service-icon">
                                    <i class="flaticon-paint-1"></i>
                                </div> */}
                                <h3 class="service-title">
                                    <Link to="/service">RTGS/NEFT</Link>
                                </h3>
                                {/* <p class="service-text">
                                    There are many variations of passages available but the majority have suffered in some
                                    form by injected humour.
                                </p> */}
                                {/* <div class="service-arrow">
                                    <Link to="/service" class="service-read-btn" style={{ color: 'green' }}>Learn More<i class="far fa-arrow-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-item">
                                <div class="service-img">
                                    <img src="assets/img/service/icon-17.svg" alt />
                                </div>
                                {/* <div class="service-icon">
                                    <i class="flaticon-paint"></i>
                                </div> */}
                                <h3 class="service-title">
                                    <Link to="/service">SMS Facility</Link>
                                </h3>
                                {/* <p class="service-text">
                                    There are many variations of passages available but the majority have suffered in some
                                    form by injected humour.
                                </p> */}
                                {/* <div class="service-arrow">
                                    <Link to="/service" class="service-read-btn" style={{ color: 'green' }}>Learn More<i class="far fa-arrow-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-item">
                                <div class="service-img">
                                    <img src="assets/img/service/icon-18.svg" alt />
                                </div>
                                {/* <div class="service-icon">
                                    <i class="flaticon-paint-3"></i>
                                </div> */}
                                <h3 class="service-title">
                                    <Link to="/service">Locker Facility</Link>
                                </h3>
                                {/* <p class="service-text">
                                    There are many variations of passages available but the majority have suffered in some
                                    form by injected humour.
                                </p> */}
                                {/* <div class="service-arrow">
                                    <Link to="/service" class="service-read-btn" style={{ color: 'green' }}>Learn More<i class="far fa-arrow-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-item">
                                <div class="service-img">
                                    <img src="assets/img/service/icon-19.svg" alt />
                                </div>
                                <div class="service-icon">
                                    {/* <i class="flaticon-paint-brushes"></i> */}
                                </div>
                                <h3 class="service-title">
                                    <Link to="/service">Debit Card</Link>
                                </h3>
                                {/* <p class="service-text">
                                    There are many variations of passages available but the majority have suffered in some
                                    form by injected humour.
                                </p> */}
                                {/* <div class="service-arrow">
                                    <Link to="/service" class="service-read-btn">Learn More<i class="far fa-arrow-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-item">
                                <div class="service-img">
                                    <img src="assets/img/service/icon-20.svg" alt />
                                </div>
                                <div class="service-icon">
                                    {/* <i class="flaticon-repair"></i> */}
                                </div>
                                <h3 class="service-title">
                                    <Link to="/">Net Banking</Link>
                                </h3>
                                {/* <p class="service-text">
                                    There are many variations of passages available but the majority have suffered in some
                                    form by injected humour.
                                </p> */}
                                {/* <div class="service-arrow">
                                    <Link to="/service" class="service-read-btn">Learn More<i class="far fa-arrow-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-item">
                                <div class="service-img">
                                    <img src="assets/img/service/icon-21.svg" alt />
                                </div>
                                <div class="service-icon">
                                    {/* <i class="flaticon-paint-roller"></i> */}
                                </div>
                                <h3 class="service-title">
                                    <Link to="/service">POS (QR Code)</Link>
                                </h3>
                                {/* <p class="service-text">
                                    There are many variations of passages available but the majority have suffered in some
                                    form by injected humour.
                                </p>
                                <div class="service-arrow">
                                    <Link to="/service" class="service-read-btn">Learn More<i class="far fa-arrow-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

</main>

  );
}
