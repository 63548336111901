// import React from 'react'
// import { Link } from 'react-router-dom';
// export default function NavBar() {
//   return (
//     <nav className="navbar navbar-expand-lg">
//       <div className="container">
//         <Link className="navbar-brand" to="/">
//           {/* <img src="assets/img/logo/logo_1.png" alt="logo"style={{height:'50px',width:'100%'}} /> */}
//         </Link>
//         <div className="mobile-menu-right">
//           {/* <Link href="#" className="mobile-search-btn search-box-outer"><i className="far fa-search"></i></Link> */}
//           <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
//             <span className="navbar-toggler-icon"><i className="far fa-stream"></i></span>
//           </button>
//         </div>
//         <div className="collapse navbar-collapse" id="main_nav">
//           <ul className="navbar-nav ms-auto">
//             <li className="nav-item"><Link className="nav-link" to="/"> Home </Link></li>
//             <li className="nav-item"><Link className="nav-link" to="/about"> About Nsbl</Link></li>
//             <li className="nav-item"><Link className="nav-link" to="/service"> Our Services </Link></li>
//             {/* <li className="nav-item"><Link className="nav-link" to="/project"> Project </Link></li> */}
//             {/* <li className="nav-item"><Link className="nav-link" to="/grievanceredressal">Grievance Redressal</Link></li> */}
//             <li className="nav-item"><Link className="nav-link" to="/grievanceredressal">Solutions</Link></li>
//             <li className="nav-item"><Link className="nav-link" to="/grievanceredressal">Become a merchant</Link></li>
//             <li className="nav-item"><Link className="nav-link" to="/grievanceredressal">Partener with us</Link></li>
//             <li className="nav-item"><Link className="nav-link" to="/feedbackcomplaints">Feedback & Complaints</Link></li>
//             {/* <li className="nav-item"><Link className="nav-link" to="/blog"> Blog </Link></li> */}
//             <li className="nav-item"><Link className="nav-link" to="/contactus">Developer Login</Link></li>
//             {/* <li className="nav-item"><Link className="nav-link" to="/contactus">Contact</Link></li> */}

//             {/* <li className="nav-item"><Link className="nav-link" to="/login">Login</Link></li> */}

//             {/* <li className="nav-item dropdown">
//               <Link className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Blog</Link>
//               <ul className="dropdown-menu fade-up">
//                 <li><Link className="dropdown-item" href="blog.html">Blog</Link></li>
//                 <li><Link className="dropdown-item" href="blog-single.html">Blog Single</Link></li>
//               </ul>
//             </li> */}

//           </ul>
//           <div className="header-nav-right">
//             {/* <div className="header-nav-search">
//               <Link href="#" className="search-box-outer"><i className="far fa-search"></i></Link>
//             </div> */}
//             {/* <div className="header-btn">
//               <Link href="#" className="theme-btn"style={{backgroundColor:'green'}}>Get Quote<i className="far fa-arrow-right"></i></Link>
//             </div> */}
//           </div>
//         </div>
//       </div>
//     </nav>
//   )
// }




import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="assets/img/logo/images.jpg" alt="logo" style={{ height: '50px', width: '50px' ,borderRadius:'50%'}} />
        </Link>
        <div className="mobile-menu-right">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main_nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="far fa-stream"></i>
            </span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About Nsbl
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Our Services
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="/savingaccount">
                  Saving Account
                </Link>
                <Link className="dropdown-item" to="/currentaccount">
                  Current Account
                </Link>
                <Link className="dropdown-item" to="/termdeposits">
                  Term Deposits
                </Link>
                <Link className="dropdown-item" to="/loansforeveryneed">
                  Loans for Every Need
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Solutions
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="/bankingapi">
                 Banking API
                </Link>
                <Link className="dropdown-item" to="/imps">
                  IMPS API
                </Link>
                <Link className="dropdown-item" to="/upicollectionapi">
                  UPI COLLECTION API
                </Link>
                <Link className="dropdown-item" to="bbpsapi">
                  BBPS API
                </Link>
                <Link className="dropdown-item" to="/aepsapi">
                  AEPS API
                </Link>
                <Link className="dropdown-item" to="/aadharpayapi">
                  AADHAR PAY
                </Link>
                <Link className="dropdown-item" to="/pennydropapi">
                  PENNY DROP API
                </Link>
              </div>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/feedbackcomplaints">
               Become a merchant
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Partner with us
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="/becomeamerchant">
                  Become a merchant
                </Link>
                <Link className="dropdown-item" to="becaomeanapi">
                  Become  An Api Partner
                </Link>
                <Link className="dropdown-item" to="gettingstarted">
                Getting Started
                </Link>
                <Link className="dropdown-item" to="developerportal">
                Developer Portal
                </Link>
                {/* <Link className="dropdown-item" to="/partner">
                 Term Deposits
                </Link> */}
                {/* <Link className="dropdown-item" to="/partner">
                 Lones
                </Link> */}
              </div>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/feedbackcomplaints">
                Feedback & Complaints
              </Link>
            </li> */}
             <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Support
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="contactcustomersupport">
                Contact Customer<br></br> Support
                </Link>
                <Link className="dropdown-item" to="submitaformalcomplaints">
                Submit a Formal <br></br>Complaint
                </Link>
                <Link className="dropdown-item" to="escalation">
                Escalation to the Dispute<br></br> Resolution Team
                </Link>
                <Link className="dropdown-item" to="externalmediationservices">
                External Mediation <br></br>Services
                </Link>
                <Link className="dropdown-item" to="regulatoryauthoritycontact">
                Regulatory Authority<br></br>Contact
                </Link>
                <Link className="dropdown-item" to="ombudsmanservices">
                Ombudsman Services
                </Link>
                <Link className="dropdown-item" to="importanttipsforresolution">
                Important Tips<br></br> for Resolution
                </Link>
                {/* <Link className="dropdown-item" to="#">
                 Net Banking Login
                </Link> */}
                {/* <Link className="dropdown-item" to="/partner">
                 Lones
                </Link> */}
              </div>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="#">
                Support
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Login
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="#">
                 Employee Login
                </Link>
                <Link className="dropdown-item" to="#">
                 Merchant Login
                </Link>
                <Link className="dropdown-item" to="#">
                 Net Banking Login
                </Link>
                {/* <Link className="dropdown-item" to="/partner">
                 Lones
                </Link> */}
              </div>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="#">
                Developer Login
              </Link>
            </li> */}
          </ul>
          <div className="header-nav-right">{/* Add your additional elements here */}</div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
