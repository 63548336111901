// import React from 'react'

// export default function Footer() {
//     return (
//         <footer class="footer-area">
//             {/* <div class="footer-widget">
//                 <div class="container">
//                     <div class="row footer-widget-wrapper pt-100 pb-70">
//                         <div class="col-md-6 col-lg-4">
//                             <div class="footer-widget-box about-us">
//                                 <a href="#" class="footer-logo" />
//                                 <img src="assets/img/logo/logo-light.png" alt="logo">
//                                 </a>
//                                 <p class="mb-20">
//                                     We are many variations of passages available but the majority have suffered alteration
//                                     in some form by injected humour.
//                                 </p>
//                                 <div class="footer-contact">
//                                     <ul>
//                                         <li><i class="far fa-map-marker-alt"></i>25/B Milford Road, New York, USA</li>
//                                         <li><a href="tel:+21236547898"><i class="far fa-phone"></i>+2 123 654 7898</a></li>
//                                         <li><a href="https://live.themewild.com/cdn-cgi/l/email-protection#44373134342b363004213c25293428216a272b29"><i class="far fa-envelope"></i><span class="__cf_email__" data-cfemail="bfcccacfcfd0cdcbffdac7ded2cfd3da91dcd0d2">[email&#160;protected]</span></a> </li>
//                                     </ul>
//                                 </div>
//                                 <ul class="footer-social" >
//                                     <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
//                                     <li><a href="#"><i class="fab fa-instagram"></i></a></li>
//                                     <li><a href="#"><i class="fab fa-twitter"></i></a></li>
//                                     <li><a href="#"><i class="fab fa-youtube"></i></a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                         <div class="col-md-6 col-lg-2">
//                             <div class="footer-widget-box list">
//                                 <h4 class="footer-widget-title">Quick Links</h4>
//                                 <ul class="footer-list">
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> About Us</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> FAQ's</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Terms Of Service</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Privacy policy</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Our Services</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Latest Blog</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                         <div class="col-md-6 col-lg-3">
//                             <div class="footer-widget-box list">
//                                 <h4 class="footer-widget-title">Our Services</h4>
//                                 <ul class="footer-list">
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Residential Painting</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Commercial Painting</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Industrial Painting</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Wall Painting</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Craft Painting</a></li>
//                                     <li><a href="#"><i class="fas fa-caret-right"></i> Regular Painting</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                         <div class="col-md-6 col-lg-3">
//                             <div class="footer-widget-box list">
//                                 <h4 class="footer-widget-title">Newsletter</h4>
//                                 <div class="footer-newsletter">
//                                     <p>Subscribe Our Newsletter To Get Latest Update And News</p>
//                                     <div class="subscribe-form">
//                                         <form action="#">
//                                             <input type="email" class="form-control" placeholder="Your Email" />
//                                             <button class="theme-btn" type="submit">
//                                                 Subscribe Now <i class="far fa-paper-plane"></i>
//                                             </button>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div> */}
//             <div class="copyright"style={{backgroundColor:'black'}}>
//                 <div class="container">
//                     <div class="row">
//                         <center>
//                         <div class="col-lg-6 align-self-center">
//                             <p class="copyright-text">
                            
//                                 &copy; Copyright © 2022 <span id="date"></span> <a href="#" style={{color:'green'}}>Nagar Sahkari Bank Ltd. All Rights Reserved. </a> 
//                             </p>
//                         </div>
//                         </center>
//                         {/* <div class="col-lg-6 align-self-center">
//                             <ul class="footer-menu">
//                                 <li><a href="#">Support</a></li>
//                                 <li><a href="#">Terms Of Services</a></li>
//                                 <li><a href="#">Privacy Policy</a></li>
//                             </ul>
//                         </div> */}
//                     </div>
//                 </div>
//             </div>
//         </footer>
//     )
// }
// import React from 'react';

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-4">
//             <div className="footer-widget">
//               <h4>About Us</h4>
//               <p>
//                 We are dedicated to providing high-quality painting services for residential, commercial, and industrial projects.
//               </p>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="footer-widget">
//               <h4>Quick Links</h4>
//               <ul>
//                 <li><a href="#">About Us</a></li>
//                 <li><a href="#">FAQ's</a></li>
//                 <li><a href="#">Terms Of Service</a></li>
//                 <li><a href="#">Privacy Policy</a></li>
//                 <li><a href="#">Contact</a></li>
//               </ul>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="footer-widget">
//               <h4>Contact Us</h4>
//               <p>25/B Milford Road, New York, USA</p>
//               <p><a href="tel:+21236547898">+2 123 654 7898</a></p>
//               <p><a href="mailto:info@example.com">info@example.com</a></p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="footer-bottom">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-6">
//               <p>&copy; {new Date().getFullYear()} Nagar Sahkari Bank Ltd. All Rights Reserved.</p>
//             </div>
//             <div className="col-lg-6">
//               <ul className="footer-social">
//                 <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
//                 <li><a href="#"><i className="fab fa-instagram"></i></a></li>
//                 <li><a href="#"><i className="fab fa-twitter"></i></a></li>
//                 <li><a href="#"><i className="fab fa-youtube"></i></a></li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;


import React from 'react';
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    // <footer className="horizontal-footer" style={{backgroundColor:'#059429'}}>
    //   <div className="container" style={{color:'black'}} >
    //     <div className="row">
    //       <div className="col-md-4">
    //         <div className="footer-widget">
    //           {/* <h4>About Us</h4>
    //           <p >
              
    //             Providing top-notch painting services to enhance your living and working spaces.
    //           </p> */
              
    //           }
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="footer-widget">
    //           <h4>Quick Links</h4>
    //           <ul>
    //             <li><a href="#">Grievance Redressal</a></li>
    //             <li><a href="#">Feedback & Complaints</a></li>
    //             {/* <li><a href="#">Terms Of Service</a></li>
    //             <li><a href="#">Privacy Policy</a></li> */}
    //             <li><a href="#">Contact</a></li>
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="footer-widget">
    //           <h4>Contact Us</h4>
    //           <p>Address: Raja Ganj-Tehsil Chauraha, Etawah-206001</p>
    //           <p><a href="tel:+21236547898">9999999999</a></p>
    //           <p><a href="mailto:info@example.com">info@example.com</a></p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="footer-bottom">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-12"style={{color:'black'}}>
    //             <center>
    //           <p>&copy; {new Date().getFullYear()} Nagar Sahkari Bank Ltd. All Rights Reserved.</p>
    //           </center>
    //         </div>
           
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    <footer className="horizontal-footer" style={{backgroundColor: 'black' }}>
      {/* '#059429' */}
  <div className="container">
    <div className="row">
      <div className="col-md-3">
        <div className="footer-widget">
          {/* Your logo image */}
         
           <img src="assets/img/logo/images.jpg" alt="logo" style={{height: '50px', width: '55px', borderRadius:'50%',paddingLeft:'5px', paddingTop:'2px'}} />
          <p >
              
              <a href=""style={{ color: '#059429' }} >NSBL is redefining banking by placing our members at the heart of everything we do.</a>
             </p> 
          
        </div>
      </div>
      <div className="col-md-3">
        <div className="footer-widget">
          <h4 style={{ color: '#ffffff' }}>About Us</h4>
          <ul >
          <li><Link to="/about" style={{ color: '#059429' }}>About Nsbl</Link></li>
          <li><Link to="/savingaccount" style={{ color: '#059429' }}>Our Services</Link></li>
          <li><Link to="/imps" style={{ color: '#059429' }}>Solutions</Link></li>
            
          </ul>
        </div>
      </div>

      <div className="col-md-3">
        <div className="footer-widget">
          <h4 style={{ color: '#ffffff' }}>Usefull Links</h4>
          <ul>
          <li><Link to="/grievanceredressal" style={{ color: '#059429' }}>Grievance Redressal</Link></li>
          <li><Link to="/feedbackcomplaints" style={{ color: '#059429' }}>Feedback & Complaints</Link></li>
          <li><Link to="/contactus" style={{ color: '#059429' }}>Contact</Link></li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="footer-widget">
          <h4 style={{ color: '#ffffff' }}>Location</h4>
          <p><a href=""style={{ color: '#059429' }}>Address: Raja Ganj-Tehsil Chauraha, Etawah-206001</a></p>
          <p><a href="08645660038"style={{ color: '#059429' }}>08645660038</a></p>
          <p><a href="info@nsblbank.in"style={{ color: '#059429' }}>info@nsblbank.in</a></p>
        </div>
      </div>
    </div>
  </div>

  <div className="footer-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-12" style={{ color: 'black' }}>
          <center>
            <p> <a href='#'style={{ color: '#ffffff' }}>&copy; {new Date().getFullYear()} Nagar Sahkari Bank Ltd. All Rights Reserved.</a></p>
          </center>
        </div>
      </div>
    </div>
  </div>
</footer>

  );
}

export default Footer;

