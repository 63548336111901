import React from 'react'

export default function Header() {
  return (

    <header class="header">

      {/* <div class="header-top" style={{backgroundColor:'green'}}> */}
      <div class="container">
        <div class="col-md-12">
          {/* <marquee style={{color:'green'}}>
            <i className="far fa-map-marker-alt"></i>
             Address: Raja Ganj-Tehsil Chauraha, Etawah-206001
          </marquee> */}

        </div>
        <div class="header-top-wrapper">
          <div class="header-top-left">
            <div class="header-top-contact">
              <ul>
                {/* <marquee>
                  <i className="far fa-map-marker-alt"></i>
                  Address: Raja Ganj-Tehsil Chauraha, Etawah-206001
                </marquee> */}
                {/* <li><a href="#"><i class="far fa-map-marker-alt" ></i>Address: Raja Ganj-Tehsil Chauraha, Etawah-206001</a>
</li> */}
                {/* <li><a href="tel:+21236547898"><i class="far fa-phone"></i>+2 123 654 7898</a></li> */}
                {/* <li><a href="https://live.themewild.com/cdn-cgi/l/email-protection#b5dcdbd3daf5d0cdd4d8c5d9d09bd6dad8"><i class="far fa-envelope"></i><span class="__cf_email__" data-cfemail="f69f989099b6938e979b869a93d895999b"></span></a></li> */}
              </ul>
            </div>
          </div>
          {/* <div class="header-top-right">
<div class="header-top-social">
<a href="#"style={{color:'green'}}><i class="fab fa-facebook-f"></i></a>
<a href="#"style={{color:'green'}}><i class="fab fa-twitter"></i></a>
<a href="#"style={{color:'green'}}><i class="fab fa-instagram"></i></a>
<a href="#"style={{color:'green'}}><i class="fab fa-linkedin-in"></i></a>
<a href="#"style={{color:'green'}}><i class="fab fa-whatsapp"></i></a>
</div>
</div> */}
        </div>
      </div>
      {/* </div> */}
      <div class="main-navigation">

      </div>
    </header>

  )
}
