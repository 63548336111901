import React from 'react'
import { Link } from 'react-router-dom';
export default function LoansforEveryNeed() {
  return (
    <div>
    <main class="main">

        <div class="site-breadcrumb" style={{ background: `url("assets/img/slider/banner.png")` }}>
            {/* <img src="assets/img/slider/banner.png" alt="Slide 1" style={{minHeight:'200pt',width:'100%'}}/> */}
            <div class="container">
                <h2 class="breadcrumb-title">
                    Our Services</h2>
                <ul class="breadcrumb-menu">
                    <li><Link to="/"><i class="far fa-home"></i> Home</Link></li>
                    <li class="active">Loans for Every Need</li>
                </ul>
            </div>
        </div>


        <div class="about-area py-120">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <center>
              
                            <h3 class="" style={{ color: '#ff4d5e' }}> 🌟 Fulfill Your Dreams with  <span style={{ color: 'green' }}>NSBL Loan Products! 🌟</span></h3>
                        </center>


                        <div class="col-md-12">
                            <h4>Embark on a journey of financial empowerment with our range of flexible and affordable loan products at NSBL. Whether you're dreaming of a new home, expanding your business, or pursuing higher education, we have the perfect loan to make your aspirations a reality. Here's why our loan offerings stand out:</h4><br></br>
                        </div>


                    </div>
                    {/* <div class="col-lg-6">
<div class="about-left" >
<div class="">
<img src="assets/img/about/aboutBg.svg" alt />


</div>
<div class="">
<h1>25 <span>+</span></h1>
<span class="about-experience-text">Years Of Experience</span>
</div>
<div class="about-shape">
<img src="assets/img/about/shape.svg" alt />
</div>
</div>
</div> */}
                    <div class="col-lg-6">

                        <div class="about-right">
                            <div class="site-heading mb-3">

                                {/* <center>

                            <h3 class="" style={{ color: '#ff4d5e' }}> 🌟 Unlock Financial Freedom with <span style={{ color: 'green' }}> Our Savings Account! 🌟</span></h3>
                        </center>
                        <p>At NSBL, we believe that your dreams deserve a trusted companion. Introducing our Savings Account—a gateway to financial empowerment designed with your needs in mind. Here's why our Savings Account is the key to your financial freedom:</p> */}
                                {/* <span class="site-title-tagline" style={{ color: '#ff4d5e' }}>Who We Are</span> */}

                                {/* <h5 class="site-title" style={{ color: '#ff4d5e' }}>We have been working very efficiently<span style={{ color: 'green' }}>  with loan and funding for 25 years.</span> </h5> */}
                            </div>
                            {/* 💸 High-Interest Returns: */}

             
                            <h4 class="" style={{ color: '#ff4d5e' }}>🏡 Home <span style={{ color: 'green' }}> Loans:</span></h4>
                            {/* <span class="site-title-tagline" style={{ color: 'green' }}>Member-Owned</span> */}

                            <p class="about-text">Turn your dream home into a reality with our Home Loans. Benefit from competitive interest rates, flexible repayment terms, and personalized solutions tailored to your needs. NSBL is your partner in building the foundation of a secure and comfortable home.</p><br></br>

                            {/* <span class="site-title-tagline" style={{ color: 'green' }}>Financial Inclusion</span> */}
                  

                            <h4 class="" style={{ color: '#ff4d5e' }}>🚗 Vehicle  <span style={{ color: 'green' }}> Loans:</span></h4>


                            <p>Hit the road with our Vehicle Loans designed for speed and convenience. Whether it's a car, motorcycle, or commercial vehicle, our loans offer competitive rates and hassle-free processing, putting you in the driver's seat of your dreams.</p><br></br>
                            {/* <span class="site-title-tagline" style={{ color: 'green' }}>  Community-Centric</span> */}
                      

                            <h4 class="" style={{ color: '#ff4d5e' }}>💼 Business <span style={{ color: 'green' }}>Loans:</span></h4>

                            <p>Fuel the growth of your business with our Business Loans. We understand the unique challenges entrepreneurs face, and our loans provide the capital injection needed for expansion, equipment purchase, or working capital. Empower your business journey with NSBL.</p><br></br>
                         

                            <h4 class="" style={{ color: '#ff4d5e' }}> 📚 Education<span style={{ color: 'green' }}> Loans:</span></h4>

                            <p>Invest in your future with our Education Loans. We believe in the power of education, and our loans are crafted to ease the financial burden of academic pursuits. Enjoy competitive interest rates and flexible repayment options as you invest in knowledge and growth.</p>

                            {/* <div class="about-list-wrapper">
              <ul class="about-list list-unstyled">
                  <li>
                      <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                      <div class="text">
                          <p>Take a look at our round up of the best shows</p>
                      </div>
                  </li>
                  <li>
                      <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                      <div class="text">
                          <p>It has survived not only five centuries</p>
                      </div>
                  </li>
                  <li>
                      <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                      <div class="text">
                          <p>Lorem Ipsum has been the ndustry standard dummy text</p>
                      </div>
                  </li>
              </ul>
          </div> */}
                            {/* <a href="about.html" class="theme-btn" style={{ backgroundColor: 'green' }}>Discover More <i class="far fa-arrow-right"></i></a> */}
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-right">

                            <div class="site-heading mb-3">
                                {/* <span class="site-title-tagline" style={{ color: 'green' }}>About Nagar Sahkari Bank Ltd.</span> */}
                     
                                <h4 class="" style={{ color: '#ff4d5e' }}>💰 Personal <span style={{ color: 'green' }}>Loans:</span></h4>

                                {/* <h5 class="site-title" style={{ color: '#ff4d5e' }}>We have been working very efficiently<span style={{ color: 'green' }}>  with loan and funding for 25 years.</span> </h5> */}
                            </div>
                            <p class="about-text">For life's unexpected turns or planned adventures, our Personal Loans are here for you. Whether it's a wedding, a vacation, or consolidating debts, our loans offer flexibility and quick approval, giving you the financial freedom to seize the moment.</p><br></br>
                            {/* <span class="site-title-tagline" style={{ color: 'green' }}>Our Vision</span> */}
                           

                            <h4 class="" style={{ color: '#ff4d5e' }}>🌐 Online <span style={{ color: 'green' }}> Loan Application:</span></h4>

                            <p>Say goodbye to long queues! Our online loan application process is user-friendly and efficient. Apply for a loan from the comfort of your home, and our dedicated team will guide you through the process, ensuring a smooth and hassle-free experience.</p><br></br>
                       
                            <h4 class="" style={{ color: '#ff4d5e' }}> 🛡️ Trust<span style={{ color: 'green' }}> and Transparency:</span></h4>


                            <p>As a cooperative bank, transparency and trust are at the core of our values. You can rely on NSBL for fair and transparent loan terms, with no hidden charges. Your financial well-being is our priority.
                            </p><br></br>

                            <p>Ready to turn your dreams into reality? Explore the world of possibilities with NSBL Loan Products. Visit our website or connect with our friendly team at your nearest branch to start your journey towards financial success.</p>
                            <p>NSBL - Empowering Dreams, Enriching Lives!</p>



                            {/* <div class="about-list-wrapper">
              <ul class="about-list list-unstyled">
                  <li>
                      <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                      <div class="text">
                          <p>Take a look at our round up of the best shows</p>
                      </div>
                  </li>
                  <li>
                      <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                      <div class="text">
                          <p>It has survived not only five centuries</p>
                      </div>
                  </li>
                  <li>
                      <div class="icon"><span class="fas fa-check-circle" style={{ color: 'green' }}></span></div>
                      <div class="text">
                          <p>Lorem Ipsum has been the ndustry standard dummy text</p>
                      </div>
                  </li>
              </ul>
          </div> */}
                            {/* <a href="about.html" class="theme-btn" style={{ backgroundColor: 'green' }}>Discover More <i class="far fa-arrow-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* 
<div class="counter-area mt-0">
<div class="container p-0 counter-bg">
<div class="row">
<div class="col-lg-3 col-sm-6">
<div class="counter-box">
<div class="icon"><i class="flaticon-paint-roller"></i></div>
<span class="counter" data-count="+" data-to="500" data-speed="3000">500</span>
<h6 class="title">+ Project Done</h6>
</div>
</div>
<div class="col-lg-3 col-sm-6">
<div class="counter-box">
<div class="icon"><i class="flaticon-paint-3"></i></div>
<span class="counter" data-count="+" data-to="250" data-speed="3000">250</span>
<h6 class="title">+ Satisfied Clients</h6>
</div>
</div>
<div class="col-lg-3 col-sm-6">
<div class="counter-box">
<div class="icon"><i class="flaticon-paint-brushes"></i></div>
<span class="counter" data-count="+" data-to="120" data-speed="3000">120</span>
<h6 class="title">+ Experts Painter</h6>
</div>
</div>
<div class="col-lg-3 col-sm-6">
<div class="counter-box">
<div class="icon"><i class="flaticon-pantone"></i></div>
<span class="counter" data-count="+" data-to="300" data-speed="3000">300</span>
<h6 class="title">+ Great Support</h6>
</div>
</div>
</div>
</div>
</div>


<div class="skill-area py-120">
<div class="container">
<div class="skill-wrapper">
<div class="row g-5 align-items-center">
<div class="col-lg-6 col-12">
<div class="skill-left">
<div class="skill-img">
<img src="assets/img/skill/01.jpg" alt="thumb" />
</div>
</div>
</div>
<div class="col-lg-6 col-12">
<div class="skill-right">
<span class="site-title-tagline">Our Skills</span>
<h2 class="site-title">We Offers You <span>Best Painting</span> Service</h2>
<p class="skill-details">
There are many variations of passages of Lorem Ipsum available, but the majority
have suffered alteration in some form, by injected humour, or randomised words which
don't look even slightly believable.
</p>
<div class="skills-section">
<div class="progress-box">
<h5>Resindential <span class="pull-right">85%</span></h5>
<div class="progress" data-value="85">
  <div class="progress-bar" role="progressbar"></div>
</div>
</div>
<div class="progress-box">
<h5>Commercial<span class="pull-right">65%</span></h5>
<div class="progress" data-value="65">
  <div class="progress-bar" role="progressbar"></div>
</div>
</div>
<div class="progress-box">
<h5>Industrial<span class="pull-right">75%</span></h5>
<div class="progress" data-value="75">
  <div class="progress-bar" role="progressbar"></div>
</div>
</div>
</div>
<a href="#" class="theme-btn mt-5">Learn More <i class="far fa-arrow-right"></i></a>
</div>
</div>
</div>
</div>
</div>
</div>


<div class="team-area">
<div class="container">
<div class="row">
<div class="col-lg-6 mx-auto">
<div class="site-heading text-center">
<span class="site-title-tagline">Team</span>
<h2 class="site-title">Meet <span>Experts</span></h2>
<div class="heading-divider"></div>
<p>
It is a long established fact that a reader will be distracted
</p>
</div>
</div>
</div>
<div class="row">
<div class="col-md-6 col-lg-4 col-xl-3">
<div class="team-item">
<img src="assets/img/team/01.jpg" alt="thumb" />
<div class="team-social">
<a href="#"><i class="fab fa-facebook-f"></i></a>
<a href="#"><i class="fab fa-twitter"></i></a>
<a href="#"><i class="fab fa-instagram"></i></a>
<a href="#"><i class="fab fa-linkedin-in"></i></a>
</div>
<div class="team-content">
<div class="team-bio">
<h5><a href="#">Malissa Fierro</a></h5>
<span>Painter</span>
</div>
</div>
</div>
</div>
<div class="col-md-6 col-lg-4 col-xl-3">
<div class="team-item">
<img src="assets/img/team/02.jpg" alt="thumb" />
<div class="team-social">
<a href="#"><i class="fab fa-facebook-f"></i></a>
<a href="#"><i class="fab fa-twitter"></i></a>
<a href="#"><i class="fab fa-instagram"></i></a>
<a href="#"><i class="fab fa-linkedin-in"></i></a>
</div>
<div class="team-content">
<div class="team-bio">
<h5><a href="#">Arron Rodri</a></h5>
<span>Painter</span>
</div>
</div>
</div>
</div>
<div class="col-md-6 col-lg-4 col-xl-3">
<div class="team-item active">
<img src="assets/img/team/03.jpg" alt="thumb" />
<div class="team-social">
<a href="#"><i class="fab fa-facebook-f"></i></a>
<a href="#"><i class="fab fa-twitter"></i></a>
<a href="#"><i class="fab fa-instagram"></i></a>
<a href="#"><i class="fab fa-linkedin-in"></i></a>
</div>
<div class="team-content">
<div class="team-bio">
<h5><a href="#">Chad Smith</a></h5>
<span>Painter</span>
</div>
</div>
</div>
</div>
<div class="col-md-6 col-lg-4 col-xl-3">
<div class="team-item">
<img src="assets/img/team/04.jpg" alt="thumb" />
<div class="team-social">
<a href="#"><i class="fab fa-facebook-f"></i></a>
<a href="#"><i class="fab fa-twitter"></i></a>
<a href="#"><i class="fab fa-instagram"></i></a>
<a href="#"><i class="fab fa-linkedin-in"></i></a>
</div>
<div class="team-content">
<div class="team-bio">
<h5><a href="#">Tony Pinto</a></h5>
<span>Painter</span>
</div>
</div>
</div>
</div>
</div>
</div>
</div>



<div class="faq-area py-120">
<div class="container">
<div class="row">
<div class="col-lg-6">
  <div class="faq-left">
      <div class="site-heading mb-3">
          <span class="site-title-tagline">Faq's</span>
          <h2 class="site-title my-3">General <span>frequently</span> asked questions</h2>
      </div>
      <p class="about-text">There are many variations of passages of Lorem Ipsum available,
          but the majority have suffered alteration in some form, by injected humour, or
          randomised words which don't look even.</p>
      <p>It is a long established fact that a reader will be distracted by the readable content of
          a page when looking at its layout. </p>
      <a href="#" class="theme-btn mt-5">Ask Question <i class="far fa-arrow-right"></i></a>
  </div>
</div>
<div class="col-lg-6">
  <div class="accordion" id="accordionExample">
      <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <span><i class="far fa-question"></i></span> Do I Need A Business Plan ?
              </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show"
              aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                  We denounce with righteous indignation and dislike men who
                  are so beguiled and demoralized by the charms of pleasure of the moment, so
                  blinded by desire.
              </div>
          </div>
      </div>
      <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <span><i class="far fa-question"></i></span> How Long Should A Business Plan Be
                  ?
              </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                  We denounce with righteous indignation and dislike men who
                  are so beguiled and demoralized by the charms of pleasure of the moment, so
                  blinded by desire.
              </div>
          </div>
      </div>
      <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false"
                  aria-controls="collapseThree">
                  <span><i class="far fa-question"></i></span> What Payment Gateway You Support ?
              </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse"
              aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                  We denounce with righteous indignation and dislike men who
                  are so beguiled and demoralized by the charms of pleasure of the moment, so
                  blinded by desire.
              </div>
          </div>
      </div>
  </div>
</div>
</div>
</div>
</div> */}


    </main>
</div>
  )
}
