import React from 'react'
import { Link } from 'react-router-dom';

export default function FeedbackComplaints() {

  const imageStyle = {
    minHeight: '200px',
    width: '100%',
  };

  const overlayTextStyle = {
    position: 'absolute',
    top: '60%',
    left: '22%',
    transform: 'translate(-50%, -50%)',
    color: 'white',

    padding: '10px',
    borderRadius: '5px',
    // left: '200px',
    textAlign: 'center',
  };
  return (

    <div>


      <div style={{ position: 'relative' }}>
        <img
          src="assets/img/blog/faqs_banner.jpg"
          className=""
          alt="Thumb"
          style={imageStyle}
        />
        <div style={overlayTextStyle}>
          <h3><b>Feedback & Complaints</b> </h3>
          {/* <p> Share your experience with us</p> */}
        </div>
      </div><br></br>
      {/* 
          <img src="assets/img/blog/faqs_banner.jpg" className="" alt="Thumb" style={{minHeight:'200px',width:'100%'}}/>
          <div style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(-50%, -50%)' }}>
            <h1 style={{ color: '#00406a', fontSize: '36px' }}><b>Feedback & Complaints</b></h1><br />
            <p>Share your experience with us</p>
          </div> */}




      <div>
        <section id="about" className="aboutSection">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="detailsBox">
                  <small></small>
                  <h2 style={{ fontSize: '22px' }}>Help us with your valuable feedback below, so we can help you better..</h2>
                  <p>Fields marked * are mandatory</p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>





      <section id="deposits" className="servicesSection">
        <div className="container">
          <h3 style={{ fontSize: '25px' }}>Please Enter Below or Click Here to View Status</h3>

          <div className="servicesBox">
            <form>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Customer Type</label>
                  <br />
                  <br />
                  <select className="form-control" name="field_customer_type" required>
                    <option value="_none">- Select a value -</option>
                    <option value="436">Existing customer</option>
                    <option value="437">New Customer</option>
                  </select>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Complaint Type</label>
                  <br />
                  <br />
                  <select className="form-control" name="field-complaint-type-shs-0-0" required>
                    <option value="_none">- Select a value -</option>
                    <option value="468">BBPS</option>
                    <option value="469">Demat</option>
                    <option value="553">Demat Account Closure Request</option>
                    <option value="471" className="has-children">Issuance</option>
                    <option value="552">Others</option>
                    <option value="470">UPI</option>
                    <option value="461" className="has-children">Visa</option>
                    <option value="438" className="has-children">Rupay card</option>
                    <option value="439" className="has-children">NetBanking</option>
                  </select>
                </div>
              </div>

              <br />
              <br />

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Customer Name</label>
                  <br />
                  <br />
                  <input type="text" className="form-control" name="title[0][value]" placeholder="" required />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Mobile Number</label>
                  <br />
                  <br />
                  <input type="text" className="form-control" name="title[0][value]" placeholder="" required />
                </div>
              </div>

              <br />
              <br />

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Account Number</label>
                  <br />
                  <br />
                  <input type="text" className="form-control" name="field_account_number[0][value]" placeholder="" />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Email ID</label>
                  <br />
                  <br />
                  <input type="email" className="form-control" name="field_email_id[0][value]" placeholder="" required />
                </div>
              </div>

              <br />
              <br />

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Feedback / Complaint</label>
                  <br />
                  <br />
                  <textarea className="form-control" name="field_issue[0][value]" rows="5" cols="60" placeholder=""></textarea>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label style={{ float: 'left' }}>Attach Document</label>
                  <br />
                  <br />
                  <input type="file" name="files[field_upload_issue_0]" size="22" />
                  <p style={{ fontSize: '13px' }}>
                    Please note: Only one file, not more than 2GB, can be uploaded. Allowed file types are TXT, PDF, DOC, JPG, PNG, and JPEG. One file only. 20 MB limit. Allowed types: pdf doc jpg png jpeg svg.
                  </p>
                </div>
              </div>

              <button type="submit" className="button button--primary js-form-submit form-submit btn btn-primary" style={{ backgroundColor: 'green' }} >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>

    </div >



  )
}
