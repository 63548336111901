import React from 'react'
import { Link } from 'react-router-dom';
function Project() {
    return (
        <main class="main">

            <div
                class="site-breadcrumb" style={{ background: `url("assets/img/breadcrumb/breadcrumb.jpg")` }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Project</h2>
                    <ul class="breadcrumb-menu">
                        <li><Link to="/"><i class="far fa-home"></i> Home</Link></li>
                        <li class="active">Project</li>
                    </ul>
                </div>
            </div>


            <div class="feature-area pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item">
                                <i class="flaticon-paint-brushes"></i>
                                <div class="feature-content">
                                    <h4><a href="#">Experts Painter</a></h4>
                                    <p>There are many variations of passages available but the majority have suffered in
                                        some form by injected humour.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item">
                                <i class="flaticon-graphic-tool"></i>
                                <div class="feature-content">
                                    <h4><a href="#">Quality Work</a></h4>
                                    <p>There are many variations of passages available but the majority have suffered in
                                        some form by injected humour.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item">
                                <i class="flaticon-paint"></i>
                                <div class="feature-content">
                                    <h4><a href="#">Affordable Price</a></h4>
                                    <p>There are many variations of passages available but the majority have suffered in
                                        some form by injected humour.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="feature-item">
                                <i class="flaticon-pantone"></i>
                                <div class="feature-content">
                                    <h4><a href="#">Great Support</a></h4>
                                    <p>There are many variations of passages available but the majority have suffered in
                                        some form by injected humour.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="case-area py-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline">Cases</span>
                                <h2 class="site-title">Recent <span>Projects</span></h2>
                                <div class="heading-divider"></div>
                                <p>
                                    It is a long established fact that a reader will be distracted.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="case-area ">
                        <div class="container">
                            <div class="row popup-gallery">
                                <div class="row">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="case-item">
                                            <div class="case-img">
                                                <img class="img-fluid" src="assets/img/case/01.jpg" alt/>
                                                    <a class="popup-img case-link" href="assets/img/case/01.jpg"> <i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="case-content">
                                                <div class="case-content-info">
                                                    <small>Painting</small>
                                                    <h4><a href="#">Painting In House</a></h4>
                                                </div>
                                                <a href="#" class="case-arrow"><i class="far fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="case-item">
                                            <div class="case-img">
                                                <img class="img-fluid" src="assets/img/case/02.jpg" alt/>
                                                    <a class="popup-img case-link" href="assets/img/case/02.jpg"> <i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="case-content">
                                                <div class="case-content-info">
                                                    <small>Painting</small>
                                                    <h4><a href="#">Painting In House</a></h4>
                                                </div>
                                                <a href="#" class="case-arrow"><i class="far fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="case-item">
                                            <div class="case-img">
                                                <img class="img-fluid" src="assets/img/case/03.jpg" alt/>
                                                    <a class="popup-img case-link" href="assets/img/case/03.jpg"> <i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="case-content">
                                                <div class="case-content-info">
                                                    <small>Painting</small>
                                                    <h4><a href="#">Painting In House</a></h4>
                                                </div>
                                                <a href="#" class="case-arrow"><i class="far fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="case-item">
                                            <div class="case-img">
                                                <img class="img-fluid" src="assets/img/case/04.jpg" alt/>
                                                    <a class="popup-img case-link" href="assets/img/case/04.jpg"> <i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="case-content">
                                                <div class="case-content-info">
                                                    <small>Painting</small>
                                                    <h4><a href="#">Painting In House</a></h4>
                                                </div>
                                                <a href="#" class="case-arrow"><i class="far fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="case-item">
                                            <div class="case-img">
                                                <img class="img-fluid" src="assets/img/case/05.jpg" alt/>
                                                    <a class="popup-img case-link" href="assets/img/case/05.jpg"> <i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="case-content">
                                                <div class="case-content-info">
                                                    <small>Painting</small>
                                                    <h4><a href="#">Painting In House</a></h4>
                                                </div>
                                                <a href="#" class="case-arrow"><i class="far fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="case-item">
                                            <div class="case-img">
                                                <img class="img-fluid" src="assets/img/case/06.jpg" alt/>
                                                    <a class="popup-img case-link" href="assets/img/case/06.jpg"> <i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="case-content">
                                                <div class="case-content-info">
                                                    <small>Painting</small>
                                                    <h4><a href="#">Painting In House</a></h4>
                                                </div>
                                                <a href="#" class="case-arrow"><i class="far fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="faq-area pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faq-left">
                                <div class="site-heading mb-3">
                                    <span class="site-title-tagline">Faq's</span>
                                    <h2 class="site-title my-3">General <span>frequently</span> asked questions</h2>
                                </div>
                                <p class="about-text">There are many variations of passages of Lorem Ipsum available,
                                    but the majority have suffered alteration in some form, by injected humour, or
                                    randomised words which don't look even.</p>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. </p>
                                <a href="#" class="theme-btn mt-5">Ask Question <i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span><i class="far fa-question"></i></span> Do I Need A Business Plan ?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            We denounce with righteous indignation and dislike men who
                                            are so beguiled and demoralized by the charms of pleasure of the moment, so
                                            blinded by desire.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <span><i class="far fa-question"></i></span> How Long Should A Business Plan Be
                                            ?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            We denounce with righteous indignation and dislike men who
                                            are so beguiled and demoralized by the charms of pleasure of the moment, so
                                            blinded by desire.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            <span><i class="far fa-question"></i></span> What Payment Gateway You Support ?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            We denounce with righteous indignation and dislike men who
                                            are so beguiled and demoralized by the charms of pleasure of the moment, so
                                            blinded by desire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default Project;